<template>
  <div>
    <ValidationObserver ref="observe">
      <b-form
        slot-scope="{ validate }"
        @submit.prevent="validate().then(success => {if(success) onSubmitCompanyDto()})"
      >
        <b-card no-body class="mb-2">
          <b-card-header class="py-1">
            <h4>تواصل معنا</h4>
          </b-card-header>
          <b-card-body class="pb-1">
            <b-card-text>
                <b-row>
                  <b-col cols="12" md="6">
                    <BTextInputWithValidation
                      rules="required|regex:[0-9]+[-]?[0-9]+$"
                      name="رقم الهاتف"
                      label="رقم الهاتف"
                      v-model="companyDto.phoneNumber"
                      placeholder="رقم الهاتف"
                    />
                  </b-col>
                  <b-col cols="12" md="6">
                    <BTextInputWithValidation
                      rules="required|regex:[0-9]+[-]?[0-9]+$"
                      name=" رقم الجوال"
                      label=" رقم الجوال"
                      v-model="companyDto.mobileNumber"
                      placeholder="دخل  رقم الجوال "
                    />
                  </b-col>
                  <b-col cols="12" md="6">
                    <BTextInputWithValidation
                      rules="required"
                      name="العناوين"
                      label="العناوين"
                      v-model="companyDto.address"
                      placeholder="العناوين"
                    />
                  </b-col>
                  <b-col cols="12" md="6">
                    <BTextInputWithValidation
                      rules="required"
                      name="fax"
                      label="fax"
                      v-model="companyDto.fax"
                      placeholder="ادخل رقم الfax"
                    />
                  </b-col>
                  <b-col cols="12" md="4">
                    <BTextInputWithValidation
                      rules="required"
                      name="facebook"
                      label="facebook"
                      v-model="companyDto.facebookLink"
                      placeholder="ادخل حساب facebook"
                    />
                  </b-col>
                  <b-col cols="12" md="4">
                    <BTextInputWithValidation
                      rules="required|email"
                      name="البريد الإلكتروني"
                      label="البريد الإلكتروني"
                      v-model="companyDto.email"
                      placeholder="ادخل البريد الإلكتروني"
                    />
                  </b-col>
                  <b-col cols="12" md="4" class="d-flex align-items-center justify-content-center">
                    <b-button variant="info" @click="modalShow = true" class="mt-1 text-nowrap">تحديد الموقع على الخريطة</b-button>
                  </b-col>
                </b-row>  
            </b-card-text>
          </b-card-body>
          <b-card-footer class="py-1">
            <b-button
              class="mr-1"
              variant="primary"
              style="max-width:100px"
              type="submit"
              >تعديل</b-button
            >
          </b-card-footer>
        </b-card>
      </b-form>
    </ValidationObserver>
    <ValidationObserver ref="observer_1">
      <b-form
        slot-scope="{ validate }"
        @submit.prevent="validate().then(success => {if(success) onSubmitAgentDto()})"
      >
        <b-card no-body class="mb-2">
          <b-card-header class="py-1">
            <h4>الوكيل</h4>
          </b-card-header>
          <b-card-body class="pb-1">
            <b-card-text>
              <b-row>
                <b-col cols="12" md="6" lg="4">
                  <label>الشعار</label>
                  <fileDragDrop
                    @uploaded="uploadThumbImage"
                    id="employee-photo"
                    height="100px"
                    type="image"
                    title="صورة جديدة"
                  />
                  <img
                    v-if="!!agentDto.imageDto.base64 || !!agentDto.imageDto.path"
                    :src="!agentDto.imageDto.base64 ? agentDto.imageDto.path.replace('~', $store.getters['app/domainHost']) : agentDto.imageDto.base64"
                    style="max-height: 320px; object-fit: fill;"
                    class="w-100"
                  />
                </b-col>
                <b-col class="8">
                  <b-row>
                    <b-col cols="12">
                      <BTextInputWithValidation
                        rules="required"
                        name="الاسم"
                        label="الاسم"
                          v-model="agentDto.name"
                        placeholder="الاسم"
                      />
                    </b-col>
                    <b-col cols="12" lg="6">
                      <BTextInputWithValidation
                        rules="required|integer"
                        name="رقم الهاتف"
                        label="رقم الهاتف" 
                        v-model="agentDto.phoneNumber"
                        placeholder="ادخل رقم الهاتف"
                      />
                    </b-col>
                    <b-col cols="12" lg="6">
                      <BTextInputWithValidation
                        rules="required|integer"
                        name=" رقم الجوال"
                        label=" رقم الجوال"
                        v-model="agentDto.mobileNumber"
                        placeholder="ادخل  رقم الجوال"
                      />
                    </b-col>
                    <b-col cols="12" lg="6">
                      <BTextInputWithValidation
                        rules="required|email"
                        name="البريد الإلكتروني"
                        label="البريد الإلكتروني"
                        v-model="agentDto.email"
                        placeholder="ادخل البريد الإلكتروني"
                      />
                    </b-col>
                    <b-col cols="12" lg="6">
                      <BTextInputWithValidation
                        rules="required"
                        name="facebook"
                        label="facebook"
                        v-model="agentDto.facebookLink"
                        placeholder="ادخل حساب facebook"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
          <b-card-footer class="py-1">
            <b-button
              class="mr-1"
              variant="primary"
              style="max-width:100px"
              type="submit"
              >تعديل</b-button
            >
          </b-card-footer>
        </b-card>
      </b-form>
    </ValidationObserver>
    <div>
      <b-modal v-model="modalShow" size="lg">
        <template #modal-title>
          <h5>اختر موقع</h5>
        </template>
        <template #default>
          <mapCompenets :latProp="companyDto.latitude" :lngProp="companyDto.longitude" @getLatLng="(lat, long) => {latitude = lat; longitude = long}" canMoveMarker />
        </template>
        <template #modal-footer>
          <b-button
            class="mr-1"
            variant="outline-primary"
            @click="modalShow = false"
          >عودة</b-button
          >
          <b-button
            variant="primary"
            @click="setLatLong"
          >تم</b-button
          >
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
  import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
  import { ValidationObserver } from "vee-validate";
  import { mapActions, mapGetters } from "vuex";
  import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
  import mapCompenets from "@/views/website/pages/contact/components/map.vue"

  export default {
    components: {
      BTextInputWithValidation,
      ValidationObserver,
      fileDragDrop,
      mapCompenets
    },
    data: () => ({
      latitude: null,
      longitude: null,
      modalShow: false,
    }),
    computed: {
      ...mapGetters(["companyDto", "agentDto"]),
    },
    created() {
      this.getContactInfo();
    },
    methods: {
      ...mapActions(["getContactInfo", "setContactInfo"]),
      uploadThumbImage(file) {
          this.agentDto.imageDto.base64 = file[0].base64;
      },
      onSubmitAgentDto() {
        console.log(this.agentDto)
        this.setContactInfo(this.agentDto)
      },
      onSubmitCompanyDto() {
        console.log(this.companyDto)
        this.setContactInfo(this.companyDto)
      },
      setLatLong() {
        this.modalShow = false
        this.companyDto.latitude = this.latitude
        this.companyDto.longitude = this.longitude
        console.log(this.companyDto)
      }
    },
  };
</script>
