<template>
<div>
    <div id="map" style="width: 100%; height: 400px" data-aos="fade-up" data-aos-duration="3000"></div>
</div>
</template>
<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
  data: () => ({
    myLatlng: {},
    zoom:13,
    map: null,
    markers: [],
    lat:null,
    lng: null,
  
  }),
  props: {
    canMoveMarker: Boolean,
    latProp: String,
    lngProp: String,
  },
  created() {
    this.initMap();
  },
  methods: {
    initMap() {
        this.lat = parseFloat(this.latProp)
        this.lng = parseFloat(this.lngProp)
        if (isNaN(this.lat) || isNaN(this.lng)) {
            this.myLatlng = { lat: 36.204, lng: 37.135 }
        } else {
            this.myLatlng = { lat: this.lat, lng: this.lng }
            this.zoom = 13
        }
        const loader = new Loader({
            apiKey: "AIzaSyCAke055qbFvoFY57TIDmCF7oX8_XoooxA",
            version: "weekly",
            libraries: ["places"]
        })
        loader.load().then(() => {
            // eslint-disable-next-line no-undef
            this.map = new google.maps.Map(document.getElementById("map"), {
                center: this.myLatlng,
                zoom: this.zoom,
                minZoom: 3,
                mapTypeId: "roadmap"
            })
            
            this.addMarker(this.myLatlng, this.map)
            if(this.canMoveMarker) {
                this.map.addListener("click", (event) => {
                    this.addMarker(event.latLng, this.map)
                    this.lat = event.latLng.toJSON().lat.toString()
                    this.lng = event.latLng.toJSON().lng.toString()
                    this.$emit("getLatLng", this.lat, this.lng);
                })
            }
        })
    },
    addMarker(location, map) {
        if (this.markers.length != 0) {
            this.markers[0].setMap(null);
            this.markers = [];
        }

        // eslint-disable-next-line no-undef
        const marker = new google.maps.Marker({
            position: location,
            map: map,
        });
        this.markers.push(marker);
    },
  },
}
</script>
